import css from './sass/style.scss';
//import { darker } from './js/Snippets.js';
//import { tooltip } from './js/Snippets.js';
//import { typing } from './js/Snippets.js';

//darker();
//tooltip();
//typing();

//require('./js/vue');
require('./js/anims');
require('./js/smoothscroll');
//require('./js/polyfills');
require('./js/fonts');
require('./js/init');
require('./js/ContactForm');
//require('./js/Framework');
require('./js/Carousel');

